import styled from "styled-components"

const Line = styled.hr`
  border: 1px solid ${props => props.theme.colors.primary.green};
  margin: 42px 0;

  @media (${props => props.theme.mediaQueries.desktop}) {
    display: none;
  }
`

export default Line
