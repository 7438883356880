import styled from "styled-components"

const Wrapper = styled.div`
  margin-top: 42px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-top: 60px;
  }
`

export default Wrapper
