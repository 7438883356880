import React, { useContext, useEffect } from "react"
import Context from "../store/Context"

//React components imports
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Trips from "../components/Trips"

const TripsPage = () => {
  const { dispatch } = useContext(Context)

  useEffect(() => {
    dispatch({ type: "SET_PATH", path: "" })
  }, [dispatch])

  return (
    <Layout>
      <PageTitle>Výlety</PageTitle>
      <Trips />
    </Layout>
  )
}

export default TripsPage
