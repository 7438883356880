import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (${props => props.theme.mediaQueries.desktop}) {
    flex-direction: row-reverse;
    justify-content: center;
  }

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    justify-content: flex-end;
  }
`

export default Container
