import React from "react"
import { useStaticQuery, graphql } from "gatsby"

//React component import
import Trip from "./Trip"

//Styled component import
import Wrapper from "./Wrapper"

const Trips = () => {
  const data = useStaticQuery(graphql`
    {
      allTripsJson {
        edges {
          node {
            id
            name
            description
            distance
            image
            imageCaption
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      {data.allTripsJson.edges.map(({ node }) => {
        return (
          <Trip
            name={node.name}
            description={node.description}
            distance={node.distance}
            imagePath={node.image}
            imageCaption={node.imageCaption}
            key={node.id}
          />
        )
      })}
    </Wrapper>
  )
}

export default Trips
