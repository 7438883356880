import React from "react"
import { graphql, useStaticQuery } from "gatsby"

//Styled components imports
import Title from "./Title"
import SectionTitle from "./SectionTitle"
import ImageContainer from "./ImageContainer"
import Image from "./Image"
import ImageCaption from "./ImageCaption"
import Text from "./Text"
import Line from "./Line"
import TextContainer from "./TextContainer"
import Wrapper from "./Wrapper"
import Container from "./Container"

const Trip = ({ name, description, distance, imagePath, imageCaption }) => {
  const images = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "trips" } }) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 430) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    }
  `)
  return (
    <>
      {images.allFile.edges
        .filter(({ node }, i) => {
          node.index = i
          return node.childImageSharp.fluid.originalName === imagePath
        })
        .map(image => {
          return (
            <Wrapper>
              <Title>{name}</Title>
              <Container>
                <ImageContainer>
                  <Image
                    fluid={image.node.childImageSharp.fluid}
                    alt={name}
                    key={image.node.id}
                  />
                  <ImageCaption>{imageCaption}</ImageCaption>
                </ImageContainer>
                <TextContainer>
                  <SectionTitle distance>Vzdialenosť</SectionTitle>
                  <Text>{distance}</Text>
                  <SectionTitle description>Popis</SectionTitle>
                  <Text>{description}</Text>
                  <Line />
                </TextContainer>
              </Container>
            </Wrapper>
          )
        })}
    </>
  )
}

export default Trip
