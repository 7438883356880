import styled from "styled-components"

const SectionTitle = styled.h3`
  color: ${props => props.theme.colors.primary.green};
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 400;
  margin-top: 24px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin-top: ${props => (props.distance ? "0px" : "42px")};
  }
`

export default SectionTitle
