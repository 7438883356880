import styled from "styled-components"

const Title = styled.h2`
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    text-align: left;
  }
`

export default Title
