import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  margin-left: -25px;
  margin-right: -25px;
  border: 2px solid ${props => props.theme.colors.imageBorder.grey};

  @media (${props => props.theme.mediaQueries.desktop}) {
    margin: 0;
    min-width: 315px;
    border: 5px solid ${props => props.theme.colors.imageBorder.grey};
  }
`

export default Image
