import styled from "styled-components"

const Wrapper = styled.div`
  @media (${props => props.theme.mediaQueries.desktop}) {
    border: 1px solid ${props => props.theme.colors.primary.green};
    margin-bottom: 36px;
    padding: 46px 42px;
  }
`

export default Wrapper
